import React, { FC } from 'react';

import Image from 'common/Image';

import { SimpleBannerImageProps } from './models';

import './SimpleBannerImage.scss';

const SimpleBannerImage: FC<SimpleBannerImageProps> = ({ backgroundImage }) => {
  if (!backgroundImage) return null;

  return (
    <Image
      imageData={backgroundImage}
      alt={backgroundImage.altText}
      className="simple-banner__bg-image"
      aria-hidden="true"
    />
  );
};

export default SimpleBannerImage;
