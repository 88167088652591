import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';
import { useBreadcrumbs } from 'hooks/useBreadcrumbs';
import { debounce } from 'lodash';

import { isBrowser } from 'utils/browser';
import { FIRST_LEVEL_PAGE_LEVEL } from 'utils/constants';

import { IBreadcrumb } from '../../hooks/useBreadcrumbs/models';
import { BreadcrumbsProps } from './models';

import './Breadcrumbs.scss';

const Breadcrumbs: FC<BreadcrumbsProps> = ({ level, pathnameUrl }) => {
  const { breadcrumbs } = useBreadcrumbs({ pathnameUrl });
  const wrapRef = useRef<HTMLDivElement>(null);
  const gradientRef = useRef<HTMLDivElement>(null);
  const [screen, setScreen] = useState<number | boolean>();
  const [isOverflowed, setIsOverflowed] = useState<boolean>();

  const handleGradient = () => {
    if (gradientRef.current && wrapRef.current && screen) {
      if (wrapRef.current.offsetWidth < gradientRef.current.scrollWidth) {
        setIsOverflowed(true);
      } else {
        setIsOverflowed(false);
      }
    }
  };

  const handleResize = debounce(() => {
    setScreen(window.innerWidth);
  }, 500);

  useEffect(() => {
    if (isBrowser()) {
      setScreen(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (!screen) return;

    handleGradient();
  }, [screen]);

  if (!breadcrumbs.length) return null;

  const BreadcrumbLinkNode = ({ title, link, isLastPage }: IBreadcrumb): ReactNode =>
    !isLastPage ? (
      <div key={link} className="breadcrumbs__link-wrapper breadcrumb">
        <Link className="breadcrumbs__link" to={link}>
          {title}
        </Link>
        <span className="breadcrumbs__icon">&#x276F;</span>
      </div>
    ) : (
      <span key={link} className="breadcrumbs__current breadcrumb" aria-current="page">
        {title}
      </span>
    );

  const BreadcrumbsRender = () => (
    <div className="breadcrumbs__container">
      <div
        className={classNames('breadcrumbs__holder', {
          'breadcrumbs__holder--overflow': isOverflowed,
        })}
        ref={gradientRef}
      >
        {breadcrumbs.map((breadcrumb) => BreadcrumbLinkNode(breadcrumb))}
      </div>
    </div>
  );

  if (level <= FIRST_LEVEL_PAGE_LEVEL) return null;

  return (
    <div data-testid="breadcrumbs" ref={wrapRef} className={classNames('breadcrumbs')}>
      <BreadcrumbsRender />
    </div>
  );
};

export default Breadcrumbs;
