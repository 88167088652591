import React, { FC } from 'react';

import BannerWithCommitmentCards from 'components/BannerWithCommitmentCards';
import BannerWithoutCards from 'components/BannerWithoutCards';
import BannerWithSimpleCards from 'components/BannerWithSimpleCards';
import SimpleBanner from 'components/SimpleBanner';

import { MediaLandingBannerRendererProps } from './models';

const Components = {
  BannerWithoutCards,
  BannerWithSimpleCards,
  BannerWithCommitmentCards,
  SimpleBanner,
};

const MediaLandingBannerRenderer: FC<MediaLandingBannerRendererProps> = (banner) => {
  if (typeof Components[banner.__typename] === 'undefined') {
    return null;
  }

  const Component = Components[banner.__typename];

  return <Component key={banner.__typename} {...banner} />;
};

export default MediaLandingBannerRenderer;
