import React, { FC, useMemo } from 'react';
import classNames from 'classnames';

import { Col, Container, Row } from 'layout';
import Image from 'common/Image';
import LinkItem from 'common/LinkItem';

import { SingleCtaProps } from './models';

import './SingleCta.scss';

const SingleCta: FC<SingleCtaProps> = ({ module, subModule }) => {
  const element = useMemo(
    () => (
      <div className="single-cta__holder">
        <Row>
          <Col xl="5" md="6" sm="12" className="single-cta__image-container col--no-gutters">
            <Image
              className="single-cta__image"
              imageData={module.image}
              alt={module.image.altText}
            />
          </Col>
          <Col xl="7" md="6" sm="12" className="single-cta__content col--no-gutters">
            <div className="single-cta__content-holder">
              {module.sectionTag ? (
                <span className="single-cta__tag">{module.sectionTag}</span>
              ) : null}
              <h2 className="single-cta__title h4">{module.title}</h2>
              <p className="single-cta__text">{module.text}</p>
              <LinkItem
                link={module.link}
                linkClass="single-cta__link link link--pink"
                titleClass="single-cta__link-title"
                hasIcon
                isLink
                showTitle
              />
            </div>
          </Col>
        </Row>
      </div>
    ),
    [module]
  );

  return !subModule ? (
    <section
      className={classNames('single-cta', {
        'single-cta--bg': module.isBackgroundWhiteAndGrey,
        section: !module.isBackgroundWhiteAndGrey,
      })}
    >
      <Container>{element}</Container>
    </section>
  ) : (
    <Container>
      <div className="single-cta">{element}</div>
    </Container>
  );
};

export default SingleCta;
