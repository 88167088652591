import { getAllSiteNodesParams, SiteNode } from './models';

const ldsPageComponentChunkName: string =
  'component---gatsby-theme-husky-src-templates-lds-page-lds-page-tsx';

export const getAllSiteNodes = ({ nodes, lang }: getAllSiteNodesParams): SiteNode[] =>
  nodes
    .filter((item) => {
      const LANG_NULL = null;

      if (item.context?.lang && lang) {
        return [lang, LANG_NULL].includes(item.context?.lang);
      }

      return true;
    })
    .map(({ id, path, componentChunkName, context }) => ({
      name: context?.name,
      title: context?.title,
      id,
      path,
      isLdsPage: componentChunkName?.includes(ldsPageComponentChunkName),
      pageConfiguration: context?.pageConfiguration,
    }));
