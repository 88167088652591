import React, { FC } from 'react';

import LinkItem from 'common/LinkItem';

import { TitleAndDownloadItemProps } from './models';

import './TitleAndDownloadItem.scss';

const TitleAndDownloadItem: FC<TitleAndDownloadItemProps> = ({ title, ctaLink }) => (
  <div className="title-and-download__inner-block">
    <h2 className="title-and-download__title">{title}</h2>
    <LinkItem
      link={ctaLink}
      linkClass="title-and-download__link-container"
      aria-hidden="true"
      hasIcon={false}
      title={`${title} ${ctaLink[0].name}`}
      isLink
      showTitle={false}
    />
    <LinkItem
      link={ctaLink}
      linkClass="title-and-download__link link link--pink"
      titleClass="title-and-download__link-text"
      tabIndex={-1}
      hasIcon
      isLink={false}
      showTitle
    />
  </div>
);

export default TitleAndDownloadItem;
