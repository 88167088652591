import React, { FC } from 'react';

import { Col, Container, Row } from 'layout';

import { TitleAndDownloadProps } from './models';
import TitleAndDownloadItem from './TitleAndDownloadItem';

const TitleAndDownload: FC<TitleAndDownloadProps> = ({ module }) => (
  <section className="title-and-download section">
    <Container>
      <Row className="row--center">
        <Col xl="8" className="col--no-gutters">
          {module.items?.map((item) => (
            <TitleAndDownloadItem {...item} key={item.title} />
          ))}
        </Col>
      </Row>
    </Container>
  </section>
);

export default TitleAndDownload;
