import React, { FC, useContext } from 'react';

import { Col, Container, Row } from 'layout';
import BannerBase from 'components/BannerBase';
import CustomCarousel from 'components/CustomCarousel';
import DownloadLink from 'components/DownloadLink';
import { commonColorHandler } from 'utils/colorsHandler';
import { cutUrl } from 'utils/functions';

import ScreenRecognitionContext from '../../contexts/screenRecognitionContext';
import { BannerWithCommitmentCardsProps } from './models';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './BannerWithCommitmentCards.scss';

const BannerWithCommitmentCards: FC<BannerWithCommitmentCardsProps> = ({
  backgroundColor,
  pdfReportDownload,
  subheading,
  headlineText,
  description,
  cards,
}) => {
  const { isMobile, isSmallMobile } = useContext(ScreenRecognitionContext);

  const Card = ({ card, cardClass }) => {
    const url = cutUrl(card.icon.url);

    return (
      <Col xl="4" md="4" className="banner-commitment__col col--no-gutters">
        <div className={cardClass}>
          {card.icon !== null ? (
            <img className="banner-commitment__card-icon" src={url} alt={card.iconAltText} />
          ) : null}
          <h3 className="banner-commitment__card-title h5">{card.title}</h3>
          <div
            className="rte banner-commitment__card-text"
            dangerouslySetInnerHTML={{ __html: card.description }}
          />
        </div>
      </Col>
    );
  };

  const Cards = () => (
    <>
      {isMobile || isSmallMobile ? (
        <CustomCarousel
          autoPlay
          infiniteLoop
          showArrows={false}
          showStatus={false}
          showThumbs={false}
        >
          {cards?.map((card) => (
            <Card card={card} cardClass="" key={card.title} />
          ))}
        </CustomCarousel>
      ) : (
        <div className="banner-commitment__card-wrapp">
          {cards?.map((card) => (
            <Card card={card} key={card.title} cardClass="banner-commitment__card" />
          ))}
        </div>
      )}
    </>
  );

  return (
    <section className={`banner-commitment ${commonColorHandler(backgroundColor[0])}`}>
      <DownloadLink pdfReportDownload={pdfReportDownload} />
      <div className="banner-commitment__top">
        <Container>
          <Row>
            <Col xl="12" md="12" sm="12" className="banner-commitment__container col--no-gutters">
              <BannerBase
                bannerClassName="banner-commitment"
                subheading={subheading}
                headlineText={headlineText}
                description={description}
              />
            </Col>
          </Row>
        </Container>
      </div>
      <div className="banner-commitment__bottom">
        <Container>
          <Row className="banner-commitment__bottom-row">
            <Col xl="12" md="12" sm="12" className="banner-commitment__container col--no-gutters">
              <Cards />
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
};

export default BannerWithCommitmentCards;
