import React, { FC, useContext } from 'react';
import classNames from 'classnames';

import { Col, Container, Row } from 'layout';
import Image from 'common/Image';
import InternalExternalLink from 'common/InternalExternalLink';
import BannerBase from 'components/BannerBase';
import CustomCarousel from 'components/CustomCarousel';
import DownloadLink from 'components/DownloadLink';
import { commonColorHandler } from 'utils/colorsHandler';

import ScreenRecognitionContext from '../../contexts/screenRecognitionContext';
import { BannerWithSimpleCardsProps } from './models';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './BannerWithSimpleCards.scss';

const BannerWithSimpleCards: FC<BannerWithSimpleCardsProps> = ({
  bannerImage,
  backgroundColor,
  subheading,
  headlineText,
  cards,
  pdfReportDownload,
}) => {
  const { isLargeDesktop, isDesktop, isMobile, isSmallMobile } =
    useContext(ScreenRecognitionContext);

  const classesForSpan = classNames('bannerWithSimpleCards__card-span tag', {
    'tag--bold':
      (!bannerImage && backgroundColor && backgroundColor[0] === '') ||
      (!bannerImage && backgroundColor && backgroundColor.length === 0),
  });

  const Card = ({ card, cardModifier }) => (
    <div className={`bannerWithSimpleCards__${cardModifier}wrap`}>
      <span className={classesForSpan}>{card.tag}</span>
      <InternalExternalLink
        classNameLink="bannerWithSimpleCards__link-card"
        url={card.link[0].url}
        target={card.link[0].target}
        ariaLabel={card.title}
        hasIcon={false}
        isLink
        showTitle
      />
      <div className={`bannerWithSimpleCards__${cardModifier}bottom`}>
        <h3
          className={`bannerWithSimpleCards__card-title ${
            isDesktop || isLargeDesktop ? 'h5' : 'h6'
          }`}
        >
          {card.title}
        </h3>
        <InternalExternalLink
          classNameTitle="bannerWithSimpleCards__link-text"
          classNameLink={classNames('bannerWithSimpleCards__link link', {
            'link--pink':
              bannerImage ||
              backgroundColor[0] === 'bannerWithSimpleCards--pink' ||
              backgroundColor[0] === 'bannerWithSimpleCards--light-pink',
            'link--white-black': !backgroundColor[0] && !bannerImage,
          })}
          url={card.link[0].url}
          title={card.link[0].name}
          target={card.link[0].target}
          hasIcon
          isLink={false}
          showTitle
        />
      </div>
    </div>
  );

  const Cards = () => (
    <>
      {isMobile || isSmallMobile ? (
        <CustomCarousel
          autoPlay
          infiniteLoop
          showArrows={false}
          showStatus={false}
          showThumbs={false}
        >
          {cards?.map((card) => (
            <Card card={card} cardModifier="card-" key={card.title} />
          ))}
        </CustomCarousel>
      ) : (
        <>
          {cards?.map((card) => (
            <Col
              className="bannerWithSimpleCards__col col--no-gutters"
              key={card.title}
              xl="4"
              md="4"
            >
              <Card card={card} cardModifier="" />
            </Col>
          ))}
        </>
      )}
    </>
  );

  const BannerImage = () => {
    if (bannerImage === null) return null;

    return (
      <Image
        imageData={bannerImage}
        alt={bannerImage}
        className="bannerWithSimpleCards__bg-image"
        aria-hidden="true"
      />
    );
  };

  return (
    <section
      className={classNames(
        `bannerWithSimpleCards  ${commonColorHandler(backgroundColor ? backgroundColor[0] : '')}`,
        {
          'bannerWithSimpleCards--img': bannerImage,
        }
      )}
    >
      <DownloadLink pdfReportDownload={pdfReportDownload} />
      <div className="bannerWithSimpleCards__top">
        <BannerImage />
        <Container>
          <Row>
            <Col
              xl="12"
              md="12"
              sm="12"
              className="bannerWithSimpleCards__container col--no-gutters"
            >
              <BannerBase
                bannerClassName="bannerWithSimpleCards"
                subheading={subheading}
                headlineText={headlineText}
              />
            </Col>
          </Row>
        </Container>
      </div>
      <div className="bannerWithSimpleCards__bottom">
        <Container>
          <Row className="bannerWithSimpleCards__bottom-row">
            <Cards />
          </Row>
        </Container>
      </div>
    </section>
  );
};
export default BannerWithSimpleCards;
