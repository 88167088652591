import React, { FC } from 'react';
import { graphql } from 'gatsby';

import Layout from 'layout/Layout';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import Breadcrumbs from 'components/Breadcrumbs';
import MediaLandingArticles from 'components/MediaLandingArticles';

import MediaLandingBannerRenderer from './MediaLandingBannerRenderer';
import MediaLandingBodyRenderer from './MediaLandingBodyRenderer';
import { MediaLandingPageTypes } from './models';

import './MediaLandingPage.scss';

const MediaLandingPage: FC<MediaLandingPageTypes.MediaLandingPageType> = ({
  data: { commonFields, mediaLandingPage },
}) => {
  const { seoMetaTitle, seoMetaDescription, seoMetaKeywords, seoExternalHreflangs } =
    mediaLandingPage.seo;
  const {
    level,
    lang,
    title,
    banner,
    articlesSourcePages,
    numberOfArticlesPerLoad,
    mainTag,
    subTags,
    noResultsText,
    bottomModules,
    url,
  } = mediaLandingPage;
  const { articleReadingTimeText, articleWrittenByText } = commonFields;

  return (
    <Layout headerTransparent className="media-landing">
      <Seo
        {...{
          seo: mediaLandingPage.seo,
          openGraph: mediaLandingPage.openGraph,
          pageTitle: title,
          seoExternalHreflangs,
          pageUrl: url,
          lang,
        }}
      />
      <PageSchema
        type="WebPage"
        name={seoMetaTitle}
        data={{
          metaTitle: seoMetaTitle,
          metaDescription: seoMetaDescription,
          metaKeywords: seoMetaKeywords,
        }}
      />
      <div className="banners">
        <Breadcrumbs level={level} />
        {banner && <MediaLandingBannerRenderer {...banner[0]} />}
      </div>

      <MediaLandingArticles
        articlesSourcePagesIds={articlesSourcePages}
        numberOfArticlesPerLoad={numberOfArticlesPerLoad}
        mainTag={mainTag}
        subTags={subTags}
        noResultsText={noResultsText}
        lang={lang}
        readingTimeText={articleReadingTimeText}
        writtenByText={articleWrittenByText}
      />

      <div id="modules">
        <MediaLandingBodyRenderer modules={bottomModules} />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($url: String) {
    commonFields {
      articleReadingTimeText
      articleWrittenByText
    }
    mediaLandingPage(url: { eq: $url }) {
      level
      lang
      url
      seo {
        ...SEOStructureFragment
      }
      openGraph {
        ...OpenGraphFragment
      }
      title
      banner {
        ...FragmentMediaLandingBanners
      }
      articlesSourcePages
      numberOfArticlesPerLoad
      mainTag {
        name
      }
      subTags {
        name
      }
      noResultsText
      bottomModules {
        ...FragmentMediaLandingModules
      }
    }
  }
`;

export default MediaLandingPage;
