import { useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useLocation } from '@reach/router';

import { unnecessarySeparator } from '../../shared/regexs';
import { BREADCRUMBS_LINK_SEPARATOR, HOME_PATH } from './constants';
import { IBreadcrumb, IBreadcrumbsHelperReturn, useBreadcrumbsParams } from './models';
import { getAllSiteNodes } from './utils';

export const useBreadcrumbs = ({
  lang = null,
  pathnameUrl,
}: useBreadcrumbsParams = {}): IBreadcrumbsHelperReturn => {
  const [breadcrumbs, setBreadcrumbs] = useState<IBreadcrumb[]>([]);
  const { pathname } = useLocation();
  const {
    allSitePage: { nodes },
  } = useStaticQuery(graphql`
    {
      allSitePage {
        nodes {
          id
          path
          componentChunkName
          context {
            name
            title
            pageConfiguration {
              hideFromInternalSearch
              hideFromBreadcrumbs
              hideFromSitemap
            }
          }
        }
      }
    }
  `);

  const allSiteNodes = getAllSiteNodes({ nodes, lang });
  let pathElements;
  if (pathnameUrl) {
    pathElements = [HOME_PATH, ...pathnameUrl.split(BREADCRUMBS_LINK_SEPARATOR).filter(Boolean)];
  } else {
    pathElements = [HOME_PATH, ...pathname.split(BREADCRUMBS_LINK_SEPARATOR).filter(Boolean)];
  }

  const createBreadCrumbs = () => {
    let slug: string = '';

    pathElements.forEach((page, index) => {
      slug += `${BREADCRUMBS_LINK_SEPARATOR}${page}${BREADCRUMBS_LINK_SEPARATOR}`;

      const [breadCrumb] = allSiteNodes
        .filter(
          (siteNode) =>
            siteNode.path === slug.replace(unnecessarySeparator, BREADCRUMBS_LINK_SEPARATOR) &&
            siteNode.path !== '/' &&
            !siteNode.pageConfiguration?.hideFromBreadcrumbs
        )
        .map(({ title, path: link }) => ({
          title,
          link,
          isLastPage: index === pathElements.length - 1,
        }));

      if (breadCrumb === undefined) {
        return;
      }

      setBreadcrumbs((state) => [...state, breadCrumb]);
    });
  };

  useEffect(() => {
    createBreadCrumbs();
  }, []);

  return { breadcrumbs };
};
