import React, { FC, useContext } from 'react';
import classNames from 'classnames';

import { Col } from 'layout';
import { cutUrl } from 'utils/functions';

import ScreenRecognitionContext from '../../../contexts/screenRecognitionContext';
import { FlexibleStatisticItemProps } from './models';

import './FlexibleStatisticItem.scss';

const FlexibleStatisticItem: FC<FlexibleStatisticItemProps> = ({
  icon,
  statText,
  infoText,
  captionText,
  hasTitle,
  iconAltText,
}) => {
  const { isSmallMobile, isMobile } = useContext(ScreenRecognitionContext);
  const url = cutUrl(icon.url);

  return (
    <Col xl="4" md="4" sm="12" className="flexible-statistic-icons__item col--no-gutters">
      <img src={url} className="flexible-statistic-icons__icon" alt={iconAltText} />
      {React.createElement(
        hasTitle ? 'h3' : 'h2',
        {
          className: classNames('flexible-statistic-icons__stat', {
            h1: !isSmallMobile && !isMobile,
            statistic: isSmallMobile || isMobile,
          }),
        },
        statText
      )}
      {infoText
        ? React.createElement('p', {
            className: 'h6 flexible-statistic-icons__heading',
            dangerouslySetInnerHTML: { __html: infoText },
          })
        : null}
      {captionText ? (
        <div
          className="caption flexible-statistic-icons__subtext caption rte"
          dangerouslySetInnerHTML={{ __html: captionText }}
        />
      ) : null}
    </Col>
  );
};

export default FlexibleStatisticItem;
