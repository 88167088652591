import React, { FC, useEffect, useState } from 'react';
import { Configure } from 'react-instantsearch-dom';

import CustomHits from 'common/Filters/CustomHits';
import CustomResultsHandler from 'common/Filters/CustomResultsHandler';
import InstantSearchContainer from 'common/InstantSearchContainer';
import { getMediaLandingFilters } from 'utils/articlesFiltersHandler';
import { MEDIA_LANDING_NUMBER_OF_ARTICLES } from 'utils/constants';

import { MediaLandingArticlesProps } from './models';

import './MediaLandingArticles.scss';

const MediaLandingArticles: FC<MediaLandingArticlesProps> = ({
  articlesSourcePagesIds,
  numberOfArticlesPerLoad,
  mainTag,
  subTags,
  noResultsText,
  lang,
  readingTimeText,
  writtenByText,
}) => {
  const [filters, setFilters] = useState<string>('');

  useEffect(() => {
    setFilters(getMediaLandingFilters(mainTag, subTags, articlesSourcePagesIds, lang));
  }, []);

  return (
    <InstantSearchContainer indexName={`${process.env.GATSBY_ALGOLIA_INDEX}-articles`}>
      <Configure
        hitsPerPage={numberOfArticlesPerLoad || MEDIA_LANDING_NUMBER_OF_ARTICLES}
        filters={filters}
      />
      <CustomResultsHandler noResultsText={noResultsText}>
        <CustomHits
          loadMoreText=""
          isGridLayout
          lang={lang}
          hideLoadMoreButton
          readingTimeText={readingTimeText}
          writtenByText={writtenByText}
        />
      </CustomResultsHandler>
    </InstantSearchContainer>
  );
};

export default MediaLandingArticles;
