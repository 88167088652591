import React, { FC, useEffect } from 'react';

import { Col, Container, Row } from 'layout';
import Image from 'common/Image';
import BannerBase from 'components/BannerBase';
import DownloadLink from 'components/DownloadLink';

import Icon from '../../common/icons/arrow-right.svg';
import { BannerWithoutCardsProps } from './models';

import './BannerWithoutCards.scss';

const BannerWithoutCards: FC<BannerWithoutCardsProps> = ({
  heading,
  subheading,
  description,
  bannerImage,
  scrollButtonText,
  pdfReportDownload,
}) => {
  let topPosition: number | undefined;

  const scrollHandle = () => {
    window.scrollTo({
      top: topPosition,
    });
  };

  useEffect(() => {
    const modules = document?.querySelector('#modules');
    topPosition = modules?.getBoundingClientRect().top;
  }, []);

  return (
    <section className="bannerWithout-cards">
      <DownloadLink pdfReportDownload={pdfReportDownload} />
      <Image
        imageData={bannerImage}
        alt={bannerImage}
        className="bannerWithout-cards__bg-image"
        aria-hidden="true"
      />
      <Container>
        <Row>
          <Col xl="12" md="12" sm="12" className="bannerWithout-cards__container col--no-gutters">
            <div className="bannerWithout-cards__content">
              <BannerBase
                bannerClassName="bannerWithout-cards"
                subheading={subheading}
                headlineText={heading}
                description={description}
              />
            </div>
            <button
              className="bannerWithout-cards__scroll scroll-btn inner-outline"
              type="button"
              aria-label="scroll to the next section"
              onClick={scrollHandle}
            >
              <span className="bannerWithout-cards__scroll-text breadcrumb">
                {scrollButtonText}
              </span>
              <Icon
                className="bannerWithout-cards__scroll-icon"
                aria-hidden="true"
                focusable="false"
              />
            </button>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default BannerWithoutCards;
