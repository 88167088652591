import React, { FC } from 'react';
import classNames from 'classnames';

import { Col, Container, Row } from 'layout';
import BannerBase from 'components/BannerBase';
import DownloadLink from 'components/DownloadLink';
import { commonColorHandler } from 'utils/colorsHandler';

import { SimpleBannerProps } from './models';
import SimpleBannerImage from './SimpleBannerImage';

import './SimpleBanner.scss';

const SimpleBanner: FC<SimpleBannerProps> = ({
  backgroundColor,
  backgroundImage,
  subtitle,
  title,
  summaryText,
  pdfReportDownload,
  bannerShort,
}) => (
  <section
    className={classNames(`simple-banner ${commonColorHandler(backgroundColor[0])}`, {
      'simple-banner--img': backgroundImage,
    })}
  >
    <DownloadLink pdfReportDownload={pdfReportDownload} />
    <div className="simple-banner__top">
      <SimpleBannerImage backgroundImage={backgroundImage} />
      <Container>
        <Row>
          <Col
            xl="10"
            sm="12"
            className={classNames('simple-banner__container col--no-gutters', {
              'simple-banner__container--short': bannerShort,
            })}
          >
            <BannerBase
              bannerClassName="simple-banner"
              subheading={subtitle}
              headlineText={title}
              description={summaryText}
            />
          </Col>
        </Row>
      </Container>
    </div>
  </section>
);

export default SimpleBanner;
