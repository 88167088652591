import React, { FC } from 'react';

import CtaCards from 'components/CtaCards';
import FlexibleStatisticIcons from 'components/FlexibleStatisticIcons';
import SingleCta from 'components/SingleCta';
import TitleAndDownload from 'components/TitleAndDownload';
import VideoAndImageWithText from 'components/VideoAndImageWithText';

import { MediaLandingBodyRendererProps } from './models';

const Components = {
  'Title And Download': TitleAndDownload,
  'CTA Cards': CtaCards,
  'Single CTA': SingleCta,
  'Video And Image With Text': VideoAndImageWithText,
  'Flexible Statistic Icons': FlexibleStatisticIcons,
};

const MediaLandingBodyRenderer: FC<MediaLandingBodyRendererProps> = ({ modules }) => (
  <>
    {modules?.map((block, index) => {
      if (typeof Components[block.structure] === 'undefined') {
        return null;
      }

      const Component = Components[block.structure];

      return <Component key={`${block.structure}${index}`} module={block.module} />;
    })}
  </>
);

export default MediaLandingBodyRenderer;
