import { ModulesContent } from '../@types/content/modules';

const generateMainTagFilter = (mainTag: ModulesContent.ArticleMainTagType, lang: string): string =>
  `lang: "${lang}" AND articleTags.name: "${mainTag.name}"`;

const generateSubTagsFilter = (subTags: ModulesContent.ArticleSubTagType[]): string =>
  subTags.map((subTag) => `articleSubTags.name: "${subTag.name}"`).join(' OR ');

const generateMainTagsFilter = (mainTags: ModulesContent.ArticleMainTagType[]): string =>
  mainTags.map((mainTag) => `articleTags.name: "${mainTag.name}"`).join(' OR ');

export const getFilters = (
  mainTag: ModulesContent.ArticleMainTagType,
  subTags: ModulesContent.ArticleSubTagType[],
  lang: string
): string =>
  subTags && subTags.length > 0
    ? `${generateMainTagFilter(mainTag, lang)} AND (${generateSubTagsFilter(subTags)})`
    : generateMainTagFilter(mainTag, lang);

export const generateLandingPageFilters = (sourcePages: string): string => {
  const splittedIds = sourcePages.split(',');

  return splittedIds.map((id) => `landingPageId: ${id}`).join(' OR ');
};

export const generateLandingPageFiltersFromArray = (sourcePages: number[]): string =>
  sourcePages.map((id) => `landingPageId: ${id}`).join(' OR ');

export const getMediaLandingFilters = (
  mainTag: ModulesContent.ArticleMainTagType,
  subTags: ModulesContent.ArticleSubTagType[],
  sourcePages: string,
  lang: string
): string =>
  subTags && subTags.length > 0
    ? `${generateMainTagFilter(mainTag, lang)} AND (${generateSubTagsFilter(
        subTags
      )}) AND (${generateLandingPageFilters(sourcePages)})`
    : `${generateMainTagFilter(mainTag, lang)} AND (${generateLandingPageFilters(sourcePages)})`;

export const getPulledNewsFilters = (
  mainTags: ModulesContent.ArticleMainTagType[],
  sourcePages: number[],
  lang: string,
  subTags?: ModulesContent.ArticleSubTagType[]
): string =>
  subTags && subTags.length > 0 && mainTags && mainTags.length > 0
    ? `lang: "${lang}" AND ${generateMainTagsFilter(mainTags)} AND (${generateSubTagsFilter(
        subTags
      )}) AND (${generateLandingPageFiltersFromArray(sourcePages)})`
    : mainTags && mainTags.length > 0
    ? `lang: "${lang}" AND (${generateMainTagsFilter(
        mainTags
      )}) AND (${generateLandingPageFiltersFromArray(sourcePages)})`
    : `lang: "${lang}" AND (${generateLandingPageFiltersFromArray(sourcePages)})`;
