import React, { FC, useEffect, useState } from 'react';
import classNames from 'classnames';

import { Col, Container, Row } from 'layout';
import LinkItem from 'common/LinkItem';
import { arraySplitter } from 'utils/arraysHandler';

import { ModulesContent } from '../../@types/content/modules';
import FlexibleStatisticItem from './FlexibleStatisticItem';
import { FlexibleStatisticIconsProps } from './models';

import './FlexibleStatisticIcons.scss';

const FlexibleStatisticIcons: FC<FlexibleStatisticIconsProps> = ({ module }) => {
  const [rows, setRows] = useState<ModulesContent.FlexibleStatisticIconsItemType[][]>();

  useEffect(() => {
    setRows(arraySplitter(module.items, 3));
  }, []);

  return (
    <section className="flexible-statistic-icons section">
      <Container>
        {module.title ? (
          <h2 className="h2 flexible-statistic-icons__title">{module.title}</h2>
        ) : null}
        <div className="flexible-statistic-icons__rows-container">
          {rows?.map((row, index) => (
            <Row
              className={classNames('flexible-statistic-icons__row', {
                'flexible-statistic-icons__row--desktop-dual': row?.length === 2,
                'flexible-statistic-icons__row--desktop': row?.length > 2,
              })}
              key={row.length + index}
            >
              {row?.map((item) => (
                <FlexibleStatisticItem {...item} key={item.iconAltText} hasTitle={!!module.title} />
              ))}
            </Row>
          ))}
        </div>

        <Row>
          <Col
            xl="12"
            md="12"
            sm="12"
            className="col--no-gutters flexible-statistic-icons__btn-row"
          >
            <LinkItem
              link={module.ctaLink}
              linkClass="btn btn--primary btn--pink"
              hasIcon
              isLink
              showTitle
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default FlexibleStatisticIcons;
